import React from "react";
import PropTypes from "prop-types";
import { Card, CardActionArea, CardContent, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link as GatsbyLink } from "gatsby";
import { AuthorOfPublication } from "./Author";
import { lightThemedLink } from "../navigation/InternalRoute";
import routes from "../navigation/routes";

const useStyles = makeStyles(theme => ({
  cardAction: {
    margin: "1rem 0",
    color: theme.palette.secondary.contrastText,
  },
  card: {
    display: "flex",
    backgroundColor: "#fff",
    color: "inherit",
    border: "solid #fff 1px",
    "&:hover": {
      border: `${theme.palette.primary.main} solid 1px`,
      transform: "translateY(-3px)",
    },
  },
  cardContent: {
    padding: "2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 1rem",
    },
  },
  flex: {
    display: "flex",
  },
  grow: {
    grow: 1,
  },
  ...lightThemedLink,
}));

const BlogTeaser = ({ title, date, author, slug, excerpt }) => {
  const classes = useStyles();
  const appendSlugToRoute = slug.indexOf('/') === 0 ? slug.substring(1) : slug;

  return (
    <CardActionArea component={GatsbyLink}
                    to={`${routes.blog.to}${appendSlugToRoute}`}
                    className={classes.cardAction}>
      <Card component="article" className={classes.card}>

          <CardContent className={classes.cardContent}>
            <Link variant="h6" component="span" className={classes.link}>{title}</Link>
            <Typography color="inherit">{excerpt}</Typography>
            <AuthorOfPublication date={date} author={author} />
          </CardContent>

      </Card>
    </CardActionArea>
  );
};
BlogTeaser.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
};
export default BlogTeaser;