import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import PropTypes from "prop-types";
import { oo } from "../element/theme";
import thomas from "../../res/avatar/team-thomas-uta-avatar-170px-OberionIO.jpg";
import OoAvatar from "../element/graphical/OoAvatar";

const shortBioText = "Founder & full stack developer at Oberion. Likes strategy and meaningful stories. Entrepreneur and retired Minesweeper pro player.";

const GreyTextDiv = styled.div`
  color: ${oo.grey4};
  text-align: left;
`;
const AuthorDiv = styled.div`
    max-width: ${oo.textWidth}rem;
    padding: 0.5rem 0;
    margin: 0 auto;
    
    display: flex; 
    flex-flow: row nowrap;
    align-items: center;
`;

/**
 * Display author with a short bio text.
 */
export const AuthorBio = ({ author, date, readingTime }) => {
  const postInfo = `${readingTime} · ${date}`;
  return (
    <AuthorDiv>
      <OoAvatar src={thomas} alt={author} height="4rem" width="4rem"
                margin="0.5rem 1.5rem 0.5rem 0.5rem" />

      <GreyTextDiv>
        <Typography variant="subtitle1" gutterBottom>{author}</Typography>
        <Typography component="p" variant="caption" gutterBottom>{shortBioText}</Typography>
        <Typography variant="caption">{postInfo}</Typography>
      </GreyTextDiv>

    </AuthorDiv>
  );
};
AuthorBio.propTypes = {
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  readingTime: PropTypes.string.isRequired,
};

/**
 * Display author info of publication.
 */
export const AuthorOfPublication = ({ date, author }) => (
  <AuthorDiv>
    <OoAvatar src={thomas} alt={author} height="3rem" width="3rem" />
    <GreyTextDiv>
      <Typography variant="body1">{author} on {date}</Typography>
    </GreyTextDiv>
  </AuthorDiv>
);
AuthorOfPublication.propTypes = {
  date: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};
