import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../components/element/layout/Layout";
import PageBanner from "../components/element/page-element/PageBanner";
import LayoutMain from "../components/element/layout/LayoutMain";
import { oo } from "../components/element/theme";
import LayoutColumn from "../components/element/layout/LayoutColumn";
import NewsletterBanner from "../components/navigation/footer/NewsletterBanner";
import routes from "../components/navigation/routes";
import BlogTeaser from "../components/blog/BlogTeaser";
import SEO from "../components/element/SEO";

const h1Heading = "Blog";
const newsletterMessage = "Get notified when new posts are released";

const seo = {
  title: "Blog - Oberion",
  description: null,
  canonical: routes.baseUrl + routes.blog.to,
};

const Blog = (props) => {
  const { data: { allMdx: { edges } } } = props;

  return (
    <Layout showNewsletter>
      <SEO {...seo} />
      <PageBanner heading={h1Heading} />
      <NewsletterBanner path={routes.blog.to}
                        message={newsletterMessage}
                        padding="1.5rem 0.5rem"
                        extraButton />

      <LayoutMain>
        <LayoutColumn maxWidth={oo.textWidth}>

          {edges.map(({ node }) => {
            const nodeProps = {
              title: node.frontmatter.title,
              date: node.frontmatter.updated,
              author: node.frontmatter.author,
              excerpt: node.excerpt,
              slug: node.fields.slug,
            };
            return (
              <BlogTeaser key={node.id} {...nodeProps} />
            );
          })}

        </LayoutColumn>
      </LayoutMain>
    </Layout>
  );
};

export const query = graphql`
query {
  allMdx(
    filter: {
      fileAbsolutePath: {regex: "content/blog/"}, 
      frontmatter: {published: {ne: null}}
    }
    sort: {fields: [frontmatter___updated], order: DESC} 
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          updated(formatString: "DD MMMM YYYY")
          author
        }
        excerpt
        fields {slug}
      }
    }
  }
}
`;

Blog.propTypes = {
  data: PropTypes.object.isRequired,
};
export default Blog;
